// import routeKeys from '../routes/keys';

export default {
  copyright: '© NK'
  // links: [
  //   {
  //     text: 'Home',
  //     path: '/'
  //   }
  // ]
};
